// @ts-nocheck
import { ANALYTICS_EVENT, CHECKOUT_METHOD, CHECKOUT_TYPE, CLASSES, IDS, LOG_LEVEL, POPUP_WINDOW } from 'src/constants'

import { Environment, Options } from 'src/config'

import { Checkout, CheckoutOutputAttributesProps } from 'src/classes/checkout'

import {
  analyticsEvent,
  buildCheckoutUrl,
  hideUpsell,
  each,
  endAnalyticsSession,
  isAffiliate,
  isMobile,
  logger,
  nodeToString,
  orderDetailsPopup,
  sanitizeRedirectUrl,
  thaw,
  checkIsNonEmptyString,
  assigned,
} from 'src/utils'

import { shouldSkipSendingEvents } from './events'
import { attachCheckoutUrl } from './urls'

export interface CloseCheckoutProps extends Partial<CheckoutSuccessProps> {
  checkoutCompleted?: boolean
  closeData?: string
}

export interface CheckoutSuccessProps {
  checkout: {
    completed: boolean
    coupon: { coupon_code: string }
    created_at: string
    id: string
    passthrough: string
    prices: {
      customer: {
        currency: string
        total: string
        total_tax: string
        unit: string
        unit_tax: string
      }
      vendor: {
        currency: string
        total: string
        total_tax: string
        unit: string
        unit_tax: string
      }
    }
    redirect_url: string
    test_variant: string
  }
  product: {
    id: number
    name: string
    quantity: 1
  }
  user: {
    country: string
    email: string
    id: string
  }
}

export function closeCheckout(callback_data: CloseCheckoutProps, fireCustomCallback?: boolean): void {
  logger.log('Checkout frame/window has been closed.')
  // *BETA* Close the checkout upsell widget, if it exists.

  const upsellCTA: HTMLElement | null = document.getElementById(IDS.PADDLE_UPSELL_CTA) as HTMLElement
  const upsellProductId = upsellCTA?.getAttribute('data-product')

  if ((typeof window._activeCheckout != 'undefined' && window._activeCheckout.upsell) || upsellProductId) {
    hideUpsell(window._activeCheckout.upsell || upsellProductId)
  }

  // *BETA* Hide the upsell 'original' button if it exists.
  let original = document.getElementById('paddle_upsell_original')
  if (original) {
    original.setAttribute('style', 'display:none;')
  }

  fireCustomCallback =
    typeof fireCustomCallback === 'boolean' ? fireCustomCallback : typeof fireCustomCallback == 'undefined'

  // Make sure any checkout visible elements are hidden.
  hideLoading()
  // kill frame
  each(CLASSES.PADDLE_FRAME, function (element: HTMLElement) {
    element.parentNode?.removeChild(element)
  })

  // close popup
  if (typeof window.PaddleWindow != 'undefined' && !window.PaddleWindow.closed) {
    clearPopupWindowClosureCheck()
    window.PaddleWindow.close()
  }

  // This has been overwritten now that `callback_data` returns all of the required information.
  let closeObject = callback_data

  // Fire any developer-defined close callbacks
  if (fireCustomCallback && window._activeCheckout !== undefined && !shouldSkipSendingEvents()) {
    delete closeObject?.checkoutCompleted
    if (typeof window._activeCheckout.closeCallback === 'function') {
      window._activeCheckout.closeCallback(closeObject)
    } else if (typeof window[window._activeCheckout.closeCallback as any] === 'function') {
      const thisCallBack = window[window._activeCheckout.closeCallback as any] as any
      thisCallBack(closeObject)
    }
    // Reset
    fireCustomCallback = false
  }

  // If viewport is frozen, unfreeze.
  if (
    isMobile() &&
    typeof window._activeCheckout != 'undefined' &&
    (typeof window._activeCheckout.method == 'undefined' || window._activeCheckout.method !== CHECKOUT_METHOD.INLINE)
  ) {
    thaw(() => {})
  }

  // Mark checkout as closed
  Checkout.isOpen = false
}

interface CompleteCheckoutProps {
  checkoutCompleted?: boolean
  checkout: {
    id: string
    redirect_url: string
  }
}

export function completeCheckout(callback_data: CompleteCheckoutProps) {
  // This has been overwritten now that `callback_data` returns all of the required information.
  let completeObject = callback_data

  // If this is an affiliate, fire a conversion and kill cookie.
  if (isAffiliate()) {
    analyticsEvent(ANALYTICS_EVENT.CONVERSION)
    endAnalyticsSession()
  }

  // Fire any developer-defined callbacks
  // Currently we disable the redirect if a developer defined callback is set, we pass the redirect info to their callback, so they can perform it if needed.
  if (typeof window[window._activeCheckout.successCallback as any] === 'function') {
    closeCheckout({}, false)

    if (completeObject.checkoutCompleted) {
      delete completeObject.checkoutCompleted
    }

    const thisCallback = window[window._activeCheckout.successCallback as any] as any
    thisCallback(completeObject)
  } else if (typeof window._activeCheckout.successCallback === 'function') {
    closeCheckout({}, false)

    if (completeObject.checkoutCompleted) {
      delete completeObject.checkoutCompleted
    }
    window._activeCheckout.successCallback(completeObject as CheckoutSuccessProps)
  } else {
    // Redirect if the complete callback tells us to...
    if (window._activeCheckout.success && window._activeCheckout.success !== '') {
      // Check if 'data-success' is specified in the original request, this redirect takes precident over the one returned from the checkout.
      // Close the checkout if it's open
      closeCheckout({}, false)

      // Show the loader while the redirect is happening.
      showLoading()

      // Redirect the user to the return_url, give it ~2 seconds for the tracking calls to complete.
      setTimeout(function () {
        window.top && (window.top.location.href = sanitizeRedirectUrl(window._activeCheckout.success))
      }, 2100)
    } else if (assigned(callback_data?.checkout?.redirect_url)) {
      // Close the checkout if it's open
      closeCheckout({})

      // Show the loader while the redirect is happening.
      showLoading()

      // Redirect the user to the return_url, give it ~1.5 seconds for the tracking calls to complete.
      setTimeout(function () {
        window.top && (window.top.location.href = sanitizeRedirectUrl(callback_data?.checkout.redirect_url))
      }, 2100)
    } else {
      // @note Previously the order details popup was automatic, it was breaking on some peoples checkouts, so now vendors have to 'enable' it.
      if (Options.completeDetails) {
        closeCheckout({})
        orderDetailsPopup(
          completeObject?.checkout.id,
          `<div class="${CLASSES.PADDLE_DETAILS_POPUP_INTERIM_TITLE}">Success! Your transaction has been completed!</div><div class="${CLASSES.PADDLE_DETAILS_POPUP_INTERIM_MESSAGE}">Your order is now being processed and this page will update when processing is complete, an order confirmation email and receipt will be sent to the email address used during purchase.</div><div class="${CLASSES.PADDLE_DETAILS_POPUP_INTERIM_MESSAGE_SMALL}">You can close this page at any time, processing will continue in the background and your order confirmation will be emailed to you.</div>`,
        )
      }
    }
  }
}

// Show a Loading Spinner
export function showLoading(returnHtml: boolean = false): string | void {
  hideLoading()

  let keyFrameStyle = document.createElement('style')
  keyFrameStyle.type = 'text/css'
  keyFrameStyle.innerHTML =
    // eslint-disable-next-line no-multi-str
    '\
				@-webkit-keyframes rotate {\
					0% {\
						-webkit-transform: rotate(45deg);\
					}\
					100% {\
						-webkit-transform: rotate(405deg);\
					}\
				}\
				@keyframes rotate {\
					from {\
						transform: rotate(45deg);\
					}\
					to {\
						transform: rotate(405deg);\
					}\
				}'
  document.getElementsByTagName('head')[0].appendChild(keyFrameStyle)

  let htmlLoader = document.createElement('div')
  htmlLoader.setAttribute(
    'style',
    'z-index:99998; display: block; position: fixed; height: 100%; width: 100%; top: 0px; left: 0px; right: 0px; bottom: 0px; margin: 0px; padding: 0px; background: rgba(0,0,0,0.38);',
  )
  htmlLoader.setAttribute('class', CLASSES.PADDLE_LOADER)

  let mainSpinnerWrapper = document.createElement('main')
  let htmlLoaderIconWrapper =
    'align-items: center;display: flex;flex-direction: column;justify-content: center;left: 50%;margin: 0.5rem 0;position: absolute;text-align: center;top: 50%;transform: translate(-50%, -50%);width: 90%;'
  mainSpinnerWrapper.setAttribute('style', htmlLoaderIconWrapper)

  let spinner = document.createElement('div')
  let htmlLoaderIcon =
    'border: 4px solid #f3f3f3;border-radius: 50%;border-top: 4px solid #ccc;width: 34px;height: 34px;-webkit-animation: rotate 1s ease-in-out infinite forwards;animation: rotate 1s ease-in-out infinite forwards;'
  spinner.setAttribute('style', htmlLoaderIcon)

  mainSpinnerWrapper.appendChild(spinner)
  htmlLoader.appendChild(mainSpinnerWrapper)

  if (!returnHtml) {
    document.getElementsByTagName('body')[0].appendChild(htmlLoader)
  } else {
    return nodeToString(htmlLoader)
  }
}

// Hide any active Loading Spinners
export function hideLoading() {
  each(CLASSES.PADDLE_LOADER, function (loader: HTMLElement) {
    loader?.parentNode?.removeChild(loader)
  })
}

export function renderCheckoutWindow(
  productId: string,
  checkoutQuery: CheckoutOutputAttributesProps | undefined = undefined,
) {
  if (typeof window.PaddleWindow != 'undefined' && !window.PaddleWindow.closed) {
    closeCheckout({})
  }

  delete window.PaddleWindow

  window.PaddleWindow = (window.open as any)(
    '',
    'PaddlePopupWindow',
    'width=' +
      POPUP_WINDOW.width +
      ',height=' +
      POPUP_WINDOW.height +
      ',location=' +
      POPUP_WINDOW.location +
      ',menubar=' +
      POPUP_WINDOW.menubar +
      ',resizable=' +
      POPUP_WINDOW.resizable +
      ',scrollbars=' +
      POPUP_WINDOW.scrollbars +
      ',status=' +
      POPUP_WINDOW.status +
      ',toolbar=' +
      POPUP_WINDOW.toolbar +
      ',top=' +
      popupWindowPosition('top', POPUP_WINDOW.width, POPUP_WINDOW.height) +
      ',left=' +
      popupWindowPosition('left', POPUP_WINDOW.width, POPUP_WINDOW.height),
    false,
  )

  // check window is opened?
  if (typeof window.PaddleWindow != 'undefined') {
    window.PaddleWindow.document.write('<title>Loading Checkout...</title>' + showLoading(true))
    window.PaddleWindow.location.href = sanitizeRedirectUrl(
      buildCheckoutUrl(productId, checkoutQuery, CHECKOUT_TYPE.FALLBACK),
    )
    window.PaddleWindow.focus()
    checkPopupWindowClosed('PaddleWindow', true)
    logger.log('Successfully opened Paddle Checkout as a popup window.')
  } else {
    logger.log(
      '[PADDLE CLASSIC] Unable to load Paddle Checkout as a popup window (typically due to popup blocker), falling back to opening in the current page. Callbacks will not be called upon close and success.',
      LOG_LEVEL.WARNING,
    )
    window.location.href = sanitizeRedirectUrl(buildCheckoutUrl(productId, checkoutQuery, CHECKOUT_TYPE.NORMAL))
  }
}

export function popupWindowPosition(
  direction: string,
  popupWidth: number = POPUP_WINDOW.width,
  popupHeight: number = POPUP_WINDOW.height,
) {
  let dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : (window.screen as any).left
  let dualScreenTop = window.screenTop !== undefined ? window.screenTop : (window.screen as any).top

  let width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width
  let height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height

  let left = width / 2 - popupWidth / 2 + dualScreenLeft
  let top = height / 2 - popupHeight / 2 + dualScreenTop

  if (direction === 'left') {
    return left
  } else if (direction === 'top') {
    return top
  } else {
    return false
  }
}

export interface FrameProps {
  frameStyle?: string
  frameInitialHeight?: number
  frameTarget?: string
}

export function renderCheckoutFrame(
  productId: number | string,
  frameProps?: FrameProps,
  checkoutQuery: CheckoutOutputAttributesProps = {},
  inline = false,
) {
  const checkoutUrl = Options.sdk
    ? buildCheckoutUrl(productId, checkoutQuery, CHECKOUT_TYPE.FALLBACK)
    : buildCheckoutUrl(productId, checkoutQuery, CHECKOUT_TYPE.POPUP)

  // Show loading spinner while frame is loading.
  if (!inline) {
    showLoading()
  }

  window.PaddleFrame = document.createElement('iframe')
  window.PaddleFrame.id = 'pf_' + productId
  window.PaddleFrame.className = CLASSES.PADDLE_FRAME
  window.PaddleFrame.name = 'paddle_frame'
  //@ts-ignore ignore deprecation warning
  window.PaddleFrame.frameBorder = '0'
  //@ts-ignore allowTransparency do exist on HTMLIFrameElement
  window.PaddleFrame.allowTransparency = 'true'
  window.PaddleFrame.allow =
    'payment ' +
    Environment.defaults().checkoutFrontEndBase +
    ' ' +
    Environment.defaults().subscriptionManagementFrontEndBase +
    ';'

  if (inline) {
    window.PaddleFrame.classList.add(CLASSES.PADDLE_FRAME_INLINE)

    // We have to inject a <style> tag to the page
    // in order to add a rule that targets the iframe's scrollbar.
    // This is because pseudo-selectors cannot be targeted
    // by inline CSS! This fixes a scrollbar bug in Safari.
    let styleElement = document.createElement('style')
    styleElement.type = 'text/css'
    styleElement.innerHTML = `.${CLASSES.PADDLE_FRAME_INLINE}::-webkit-scrollbar { display: none !important; }`
    document.getElementsByTagName('head')[0].appendChild(styleElement)
  } else {
    window.PaddleFrame.classList.add(CLASSES.PADDLE_FRAME_OVERLAY)
  }

  const styles =
    'z-index: 99999; display: block; background-color: transparent; border: 0px none transparent; overflow-x: hidden; overflow-y: auto; visibility: visible; margin: 0px; padding: 0px; -webkit-tap-highlight-color: transparent; position: fixed; left: 0px; top: 0px; width: 100%; height: 100%; '
  const nonMobileStyles = styles + 'overflow-y: auto; position: fixed;'
  const mobileStyles = styles + 'overflow-y: scroll; position: absolute;'

  if (checkIsNonEmptyString(frameProps?.frameStyle)) {
    window.PaddleFrame.setAttribute('style', `${frameProps?.frameStyle}`)
  } else {
    if (!isMobile()) {
      window.PaddleFrame.setAttribute('style', nonMobileStyles)
    } else {
      window.PaddleFrame.setAttribute('style', mobileStyles)
    }
  }

  if (typeof frameProps?.frameInitialHeight != 'undefined') {
    window.PaddleFrame.setAttribute('height', `${frameProps?.frameInitialHeight}px`)
  }

  const browserIsSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  // This logic prevents Safari white screen bug on initial iframe loading when the cache is cleared / incognito.
  if (browserIsSafari) {
    window.PaddleFrame.style.visibility = 'hidden'
    window.PaddleFrame.onload = function () {
      window.PaddleFrame.style.visibility = 'visible'
    }
    // End Safari white screen bug fix.
  }

  attachCheckoutUrl(window.PaddleFrame, checkoutUrl)

  if (typeof frameProps?.frameTarget != 'undefined' && frameProps?.frameTarget !== '') {
    document.getElementsByClassName(frameProps?.frameTarget)[0].appendChild(window.PaddleFrame)
  } else {
    document.getElementsByTagName('body')[0].appendChild(window.PaddleFrame)
  }
}

export function clearPopupWindowClosureCheck() {
  if (typeof window.PaddleCheckWindowClosure != 'undefined') {
    window.clearInterval(window.PaddleCheckWindowClosure)
  }
}

export function checkPopupWindowClosed(popupWindow: string, newWindow = false) {
  if (newWindow) {
    window.clearInterval(window.PaddleCheckWindowClosure)
    delete window.PaddleCheckWindowClosure
  }

  if (typeof window[popupWindow] != 'undefined' && window[popupWindow].closed) {
    clearPopupWindowClosureCheck()
    closeCheckout({})
  } else {
    if (typeof window[popupWindow] != 'undefined' && typeof window.PaddleCheckWindowClosure == 'undefined') {
      window.PaddleCheckWindowClosure = window.setInterval(function () {
        checkPopupWindowClosed(popupWindow)
      }, 500)
    }
  }
}
